// @flow
import VisuallyHidden from "@reach/visually-hidden";
import graphql from "babel-plugin-relay/macro";
import { Box } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer, createFragmentContainer } from "react-relay";

import { useLabels } from "../../context_providers/LabelContext";
import { useTenant } from "../../context_providers/TenantContext";
import { useViewer } from "../../context_providers/ViewerContext";
import { getRootUrl } from "../../utils/urls";
import { ContentContainerFlex } from "../pages";

import {
  DueForReviewQuery,
  MyDocumentsToAcknowledgeQuery,
  PendingDocumentsQuery,
  RecentlyRevisedDocumentsQuery
} from "./HomeContentQueries";
import Welcome from "./Welcome";

import { buildPolicyTableStructure } from "pstat-anywhere/components/acknowledgments/mine/MyPoliciesToAcknowledgeReport";
import {
  Buckets as DocumentsForReviewBuckets,
  getBucketsConfig as getDocumentsForReviewBuketsConfig
} from "pstat-anywhere/components/document_reports/expiring/DocumentsForReviewReport";
import { shouldShowReportToggle } from "pstat-anywhere/components/document_reports/expiring/utils";
import NewAndRecentlyRevisedCSVQuery from "pstat-anywhere/components/document_reports/revised_recent/NewAndRecentlyRevisedCSVQuery";
import { Buckets as NewAndRecentlyRevisedBuckets } from "pstat-anywhere/components/document_reports/revised_recent/NewAndRecentlyRevisedReport";
import { PolicyTypeSelector } from "pstat-anywhere/components/pages/reports/components/PolicyTypeSelector";
import { useEnvironment } from "pstat-anywhere/context_providers/EnvironmentContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import { PolicyTable } from "pstat-design-system/tables";
import { H1 } from "pstat-design-system/text";



const getTranslationStrings = (tableName, t, documentLabelPlural) => {
  // Translate correct title and label for PolicyTable
  return {
    title: t(`home.tables.${tableName}.title`, {
      documentLabelPlural: titleCase(documentLabelPlural)
    }),
    label: t(`home.tables.${tableName}.label`, { documentLabelPlural }),
    noContent: t(`home.tables.${tableName}.noContent`, {
      documentLabelPlural: documentLabelPlural.toLowerCase()
    })
  };
};

export const HomeContent = ({
  currentTenant,
  pendingDocuments,
  documentsToReviewHome,
  recentlyRevisedDocuments,
  myDocumentsToAcknowledge,
  loadQueriesIndependently
}) => {
  const shouldShowWelcomeMessage = () => {
    const settings = currentTenant?.settings;
    return (
      settings?.customWelcomeContentShow && settings?.customWelcomeContentPsa
    );
  };
  // Wait for the home route query to load before starting the individual report queries
  // If don't wait then the individual queries will be triggered more than once
  const routerQueryLoading = !currentTenant;
  const shouldLoadQueryIndependently =
    !routerQueryLoading && loadQueriesIndependently;

  const viewer = useViewer();
  const tenant = useTenant();
  const { t } = useTranslation();

  return (
    <ContentContainerFlex wrap px={[3, 3, 0]}>
      <VisuallyHidden>
        <H1>{t("home.homeContent.header")}</H1>
      </VisuallyHidden>
      <Box p={[0, 0, 3]} width={1}>
        {shouldShowWelcomeMessage() && (
          <Welcome content={currentTenant.settings.customWelcomeContentPsa} />
        )}
        {shouldLoadQueryIndependently ? (
          <Fragment>
            {!viewer.isGuest && (
              <Fragment>
                <PendingDocumentsReportQueryRenderer />
                <DueForReviewReportQueryRenderer />
                {!tenant.enableAllBlocs && (
                  <MyDocumentsToAcknowledgeReportQueryRenderer />
                )}
              </Fragment>
            )}
            <NewAndRecentlyRevisedReportQueryRenderer />
          </Fragment>
        ) : (
          <Fragment>
            {!viewer.isGuest && (
              <Fragment>
                <PendingDocumentsReport pendingDocuments={pendingDocuments} />
                <DueForReviewReport
                  documentsToReviewHome={documentsToReviewHome}
                />
                {!tenant.enableAllBlocs && (
                  <MyDocumentsToAcknowledgeReport
                    myDocumentsToAcknowledge={myDocumentsToAcknowledge}
                  />
                )}
              </Fragment>
            )}
            <NewAndRecentlyRevisedReport
              recentlyRevisedDocuments={recentlyRevisedDocuments}
            />
          </Fragment>
        )}
      </Box>
    </ContentContainerFlex>
  );
};

export default createFragmentContainer(
  HomeContent,
  graphql`
    fragment HomeContent_currentTenant on TenantType {
      settings {
        customWelcomeContentShow
        customWelcomeContentPsa
      }
    }
  `
);

const PendingDocumentsReportQueryRenderer = () => {
  const environment = useEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      query={PendingDocumentsQuery}
      render={({ props }) => {
        return <PendingDocumentsReport {...props} />;
      }}
    />
  );
};

const PendingDocumentsReport = ({ pendingDocuments }) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = useLabels();
  return (
    <div id="pending_documents_report">
      <PolicyTable
        policies={pendingDocuments?.documents?.edges.map(edge => edge.node)}
        totalCount={pendingDocuments?.documents?.totalCount}
        headingContent={getTranslationStrings(
          "approve",
          t,
          documentLabelPlural
        )}
        tableType="approve"
        link={`${getRootUrl()}/policy_reports/awaiting_activation/pending`}
        viewOnlyPks={pendingDocuments?.viewOnlyPks}
      />
    </div>
  );
};

const DueForReviewReportQueryRenderer = () => {
  const environment = useEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      query={DueForReviewQuery}
      render={({ props }) => {
        return <DueForReviewReport {...props} />;
      }}
    />
  );
};

const DueForReviewReport = ({ documentsToReviewHome }) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = useLabels();
  const showReportToggle = shouldShowReportToggle(
    documentsToReviewHome?.counts
  );
  const headingContent = getTranslationStrings(
    "review",
    t,
    documentLabelPlural
  );
  if (showReportToggle) {
    headingContent.titleWithoutType = t(`home.tables.review.titleWithoutType`);
  }
  const templateSelectorTitle = t(
    "documentReports.expiring.documentTypeSelector.templateTitle",
    {
      documentLabelPlural
    }
  );
  return (
    <div id="due_for_review_report">
      <PolicyTable
        policies={documentsToReviewHome?.documents}
        totalCount={documentsToReviewHome?.totalCount}
        headingContent={headingContent}
        tableType="review"
        link={`${getRootUrl()}/policy_reports/expiring`}
        BucketsComponent={() => (
          <DocumentsForReviewBuckets
            bucketsConfig={getDocumentsForReviewBuketsConfig(
              t,
              false,
              documentsToReviewHome?.buckets
            )}
          />
        )}
        PrefixHeadingComponent={
          showReportToggle
            ? () => (
                <PolicyTypeSelector
                  policyTypes={[
                    {
                      key: "timeCount",
                      counter: documentsToReviewHome?.totalCount,
                      name: documentLabelPlural,
                      to: "/policy_reports/expiring"
                    },
                    {
                      key: "templateCount",
                      counter: documentsToReviewHome?.counts?.templateCount,
                      name: templateSelectorTitle,
                      to: "/policy_reports/expiring/template"
                    }
                  ]}
                  title={titleCase(documentLabelPlural)}
                  fontSize={[4, 4, 5]}
                />
              )
            : null
        }
      />
    </div>
  );
};

const MyDocumentsToAcknowledgeReportQueryRenderer = () => {
  const environment = useEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      query={MyDocumentsToAcknowledgeQuery}
      render={({ props }) => {
        return <MyDocumentsToAcknowledgeReport {...props} />;
      }}
    />
  );
};

const MyDocumentsToAcknowledgeReport = ({ myDocumentsToAcknowledge }) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = useLabels();
  return (
    <div id="my_documents_to_acknowledge_report">
      <PolicyTable
        tableType="my_policies_to_acknowledge_home_page"
        policies={buildPolicyTableStructure(myDocumentsToAcknowledge)}
        totalCount={myDocumentsToAcknowledge?.totalCount}
        headingContent={getTranslationStrings(
          "acknowledge",
          t,
          documentLabelPlural
        )}
        link={`${getRootUrl()}/acknowledgment/mine/`}
      />
    </div>
  );
};

const NewAndRecentlyRevisedReportQueryRenderer = () => {
  const environment = useEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      query={RecentlyRevisedDocumentsQuery}
      render={({ props }) => {
        return <NewAndRecentlyRevisedReport {...props} />;
      }}
    />
  );
};

const NewAndRecentlyRevisedReport = ({ recentlyRevisedDocuments }) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = useLabels();
  return (
    <div id="new_and_recently_revised_report">
      <PolicyTable
        policies={recentlyRevisedDocuments?.documents?.edges.map(
          edge => edge.node
        )}
        tableType="revised_recent_home_page"
        headingContent={getTranslationStrings(
          "revised",
          t,
          documentLabelPlural
        )}
        link={`${getRootUrl()}/policy_reports/revised/recent`}
        csvQuery={NewAndRecentlyRevisedCSVQuery}
        csvQueryName="recentlyRevisedDocumentsCsv"
        totalCount={recentlyRevisedDocuments?.documents?.totalCount}
        BucketsComponent={() => (
          <NewAndRecentlyRevisedBuckets
            buckets={recentlyRevisedDocuments?.buckets}
          />
        )}
      />
    </div>
  );
};
